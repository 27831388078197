import React, { useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LogIn from "./containers/LogIn";
import AppSecure from "./AppSecure";
import { onMessageListener } from "./firebase";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

function App(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});

    const handleClose = event => {
        setOpen(false);
    };

    onMessageListener()
        .then(payload => {
            setMessage(payload.notification);
            setOpen(true);
        })
        .catch(err => console.log("failed: ", err));

    if (props.needAutorization && window.location.pathname !== "/") {
        window.location.replace("/");
    }

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity="warning"
                >
                    <AlertTitle>{message.title}</AlertTitle>
                    {message.body}
                </Alert>
            </Snackbar>
            {props.preCheck ? (
                <Router>
                    <div>
                        <Route exact path="/" component={LogIn} />
                        <Route path="/dashboard" component={AppSecure} />
                    </div>
                </Router>
            ) : (
                <Router>
                    <div>
                        <Route exact path="/" component={LogIn} />
                    </div>
                </Router>
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    preCheck: state.appData.preCheck,
    needAutorization: state.authData.needAutorization
});

export default connect(
    mapStateToProps,
    {}
)(App);

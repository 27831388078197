import React from "react";
import _get from "lodash/get";
import { BrowserRouter as Router } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import theme from "./styles/theme";
import MainNavigator from "./Navigator/MainNavigator";
import MainRoutes from "./Navigator/MainRoutes";
import { signOut, getProfile } from "./actions/auth/authActions";
import Header from "./containers/Header";
import { getAccountAuth } from "./actions/account/accountActions";
import * as cookie from "react-cookie";
const cookies = new cookie.Cookies();

const drawerWidth = 200;

const styles = {
    root: {
        display: "flex",
        minHeight: "100vh"
    },
    drawer: {
        [theme.breakpoints.up("lg")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        background: "white",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        [theme.breakpoints.up("md")]: {
            width: "100%"
        },
        [theme.breakpoints.up("lg")]: {
            width: "80%"
        }
    }
};

class AppSecure extends React.Component {
    state = {
        mobileOpen: false
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleUserIconClick = () => {
        this.props.signOut();
    };

    handleAppAccount = async () => {
        // console.log(" ------ handleAppAccount ------");
        const response = await this.props.getAccountAuth();
        // console.log("response ::::: ", response);

        cookies.set("token", response.token, {
            path: "/",
            maxAge: 43200
        });

        await this.props.getProfile();

        setTimeout(() => {
            this.props.history.push(`/dashboard/accounts`);
            window.location.reload();
        }, 100);
    };

    render() {
        const {
            classes,
            needAutorization,
            accountBaseUrl,
            adminKey
        } = this.props;

        if (needAutorization) {
            // console.log("TODO: needAutorization ::::::::: ", needAutorization);
            this.props.history.push("/");
        }

        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <nav className={classes.drawer}>
                        <Hidden lgUp implementation="js">
                            <MainNavigator
                                PaperProps={{
                                    style: { width: drawerWidth }
                                }}
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                            />
                        </Hidden>
                        <Hidden mdDown implementation="css">
                            <MainNavigator
                                PaperProps={{
                                    style: { width: drawerWidth }
                                }}
                            />
                        </Hidden>
                    </nav>
                    <div className={classes.appContent}>
                        <Header
                            adminKey={adminKey}
                            handleAppAccount={this.handleAppAccount}
                            onDrawerToggle={this.handleDrawerToggle}
                            handleUserIconClick={this.handleUserIconClick}
                        />
                        <MainRoutes accountBaseUrl={accountBaseUrl} />
                    </div>
                </div>
            </Router>
        );
    }
}

AppSecure.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isDriver: _get(state, "authData.isDriver", false),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        adminKey: _get(state, "authData.profile.adminId"),
        needAutorization: _get(state, "authData.needAutorization", false)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signOut,
            getAccountAuth,
            getProfile
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppSecure)
);

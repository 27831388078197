import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./configureStore";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/theme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
const sentryDns = process.env.REACT_APP_SENTRY_DNS;

console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);

if (process.env.NODE_ENV === "production" && sentryDns) {
    console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);

    Sentry.init({
        dsn:
            "https://26597b76439e473cb73264f4fda0b9d0@o563436.ingest.sentry.io/5703406",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

class MainNavigatorLinks extends React.Component {
    onNavClick = url => {
        this.props.history.push(url);
        this.props.onClose();
    };

    render() {
        const {
            appRoutes = [],
            classes,
            isAdmin,
            isManager,
            isCustomer,
            settings
        } = this.props;
        const currentRoute = _get(this, "props.location.pathname");
        const driverId = _get(this, "props.driverId");
        const accountId = _get(this, "props.accountId");

        return (
            <div>
                {appRoutes.map(({ id, children, url }) => {
                    if (accountId) {
                        url = `${url}/${accountId}`;
                    }
                    return (
                        <React.Fragment key={id}>
                            <ListItem className={classes.categoryHeader}>
                                {/*<ListItemText classes={{ primary: classes.categoryHeaderPrimary, }}>{id}</ListItemText>*/}
                            </ListItem>
                            {children.map(
                                ({
                                    id: childId,
                                    url: childUrl,
                                    icon,
                                    active,
                                    admin,
                                    manager,
                                    customer
                                }) => {
                                    let urlFinal =
                                        childUrl === "/overview"
                                            ? "/dashboard/overview"
                                            : childUrl === "/fmcsa"
                                                ? "/dashboard/fmcsa"
                                                : childUrl === "/accounts"
                                                    ? "/dashboard/accounts"
                                                    : childUrl === "/payments"
                                                        ? "/dashboard/payments"
                                                        : childUrl === "/admin-audit"
                                                            ? "/dashboard/admin-audit"
                                                            : childUrl === "/admin-users"
                                                                ? "/dashboard/admin-users"
                                                                : childUrl === "/admin-settings"
                                                                    ? "/dashboard/admin-settings"
                                                                    : `${url}${childUrl}`;

                                    // driverId
                                    urlFinal = urlFinal.replace(
                                        ":driverId",
                                        driverId
                                    );

                                    if (isAdmin) {
                                        if (!admin) {
                                            return null;
                                        }
                                    }

                                    if (isManager) {
                                        if (!manager) {
                                            return null;
                                        }
                                    }

                                    if (isCustomer) {
                                        if (!customer) {
                                            return null;
                                        }
                                    }

                                    if (
                                        childId === "EditLogs" &&
                                        settings &&
                                        !settings.editLogs
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <ListItem
                                            button
                                            dense
                                            key={childId}
                                            className={classNames(
                                                classes.item,
                                                classes.itemActionable,
                                                currentRoute === urlFinal &&
                                                classes.itemActiveItem
                                            )}
                                            url={urlFinal}
                                            onClick={() =>
                                                this.onNavClick(urlFinal)
                                            }
                                            style={{ padding: "4px 12px" }}
                                        >
                                            <ListItemIcon
                                                style={{ minWidth: 30 }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{
                                                    primary: classes.itemPrimary
                                                }}
                                            >
                                                {childId}
                                            </ListItemText>
                                        </ListItem>
                                    );
                                }
                            )}
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

MainNavigatorLinks.defaultProps = {
    onClose: () => { }
};

const mapStateToProps = state => ({
    isAdmin: _get(state, "authData.isAdmin"),
    isCustomer: _get(state, "authData.isCustomer"),
    isManager: _get(state, "authData.isManager"),
    driverId: _get(state, "authData.driverId"),
    accountId: _get(state, "authData.accountId"),
    settings: _get(state, "authData.profile.accountProperties.settings")
});

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(MainNavigatorLinks)
);

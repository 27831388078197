import axios from "axios";
import * as cookie from "react-cookie";
import _get from "lodash/get";
import store from "../configureStore";
import { signOut } from "./auth/authActions";

const cookies = new cookie.Cookies();

axios.defaults.timeout = 100000;
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.interceptors.request.use(function requestInterceptor(config) {
    const token = cookies.get("token", {
        path: "/"
    });
    // config.headers["Token"] = token;
    config.headers["authorization"] = `Bearer ${token}`;
    config.headers["X-Api-Key"] = process.env.REACT_APP_API_KEY;
    return config;
});

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        const responseCode = _get(error, "response.data.statusCode");
        if (responseCode === 401) {
            store.dispatch(signOut());
        }

        return Promise.reject(error);
    }
);

export default axios;

import _get from "lodash/get";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const renderCheckbox = ({
    field,
    label,
    color,
    labelPlacement,
    classes,
    form: { touched, errors, values, handleChange, handleBlur }
}) => {
    let value = _get(values, `${field.name}`);

    return (
        <div>
            <FormControlLabel
                labelPlacement={labelPlacement}
                className={classes}
                control={
                    <Checkbox
                        id={field.name}
                        checked={value ? true : false}
                        color={color}
                        onChange={handleChange}
                    />
                }
                label={label}
            />
        </div>
    );
};

export default function ReduxCheckBox({
    onClick,
    xs = 12,
    sm = 12,
    md = 4,
    name,
    label,
    color = "primary",
    type = "text",
    placeholder,
    loading = false,
    labelPlacement = "end",
    classes
}) {
    const _label = label ? label : name;
    return (
        <Grid item xs={xs} sm={sm} md={md} style={{ paddingTop: 15 }}>
            <Field
                color={color}
                name={name}
                component={renderCheckbox}
                label={_label}
                labelPlacement={labelPlacement}
                classes={classes}
            />
        </Grid>
    );
}

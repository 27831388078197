import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getCustomers } from "../../actions/customer/customerActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomersTable from "./CustomersTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Customer extends React.Component {
    state = {
        open: false,
        customers: [],
        loading: false
    };

    async componentDidMount() {
        await this.getCustomers();
    }

    handleOpen = () => {
        this.setState({ open: true, customer: null });
    };

    getCustomers = async () => {
        try {
            this.setState({ loading: true });
            const customers = await this.props.getCustomers();
            if (customers && Array.isArray(customers)) {
                this.setState({ customers });
            }
            this.setState({ loading: false });
        } catch (e) {}
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getCustomers();
    };

    handleEdit = customer => {
        this.setState({ open: true, customer });
    };

    render() {
        const { classes } = this.props;
        const { customers, loading } = this.state;
        const { open, customer } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        customer={customer}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <CustomersTable
                        handleEdit={this.handleEdit}
                        items={customers}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: _get(state, "customerData.customers", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCustomers,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Customer)
);

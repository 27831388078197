import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./todoActionTypes";

export const setTodos = value => ({
    type: TYPES.TODOS,
    value
});

export const selectedTodo = value => ({
    type: TYPES.SELECTED_TODO,
    value
});

export const selectedTodoData = value => ({
    type: TYPES.SELECTED_TODO_DATA,
    value
});

export const todosCreateBusy = value => ({
    type: TYPES.TODOS_CREATE_BUSY,
    value
});

export const todosBusy = value => ({
    type: TYPES.TODOS_BUSY,
    value
});

export const todosAuthBusy = value => ({
    type: TYPES.TODOS_AUTH_BUSY,
    value
});

export function createTodo(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        dispatch(todosCreateBusy(true));
        return axios({
            url: `${BASE_URL}/secure/${accountId}/todo`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(todosCreateBusy(false));
                return response;
            });
    };
}

export function updateTodo(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/todo`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getTodosCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/todos/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setTodos(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(todosBusy(false));
                return response;
            });
    };
}

export function getTodos(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/todo`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setTodos(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(todosBusy(false));
                return response;
            });
    };
}

export function getTodoDetails(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/todos/${params.todoId}`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(selectedTodoData(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


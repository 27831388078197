import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
    getAccountProperties,
    updateAccountProperties
} from "../../../actions/account/accountActions";
import { validateProperties } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import CheckBox from "../../../components/CheckBox";
import Error from "../../Error";
import Success from "../../Success";
import { THEME } from "../../../constants";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 25,
        textAlign: "right"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class EditAccountForm extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.getAccountProperties();
    }

    getAccountProperties = async () => {
        this.setState({ loading: true });
        const accountId = _get(this, "props.selected._id");
        const accountProperties = await this.props.getAccountProperties({
            accountId
        });
        this.setState({ loading: false, accountProperties });
    };

    handleSubmit = async values => {
        this.setState({ errorMessage: "", successMessage: "", loading: true });
        let response = await this.props.updateAccountProperties(values);

        if (response && response._id) {
            this.setState({
                successMessage: "Updated successfully",
                loading: false
            });
            setTimeout(() => {
                this.props.handleClose();
            }, 1000);
        } else if (response && response.message) {
            this.setState({ errorMessage: response.message, loading: false });
        } else {
            if (typeof response !== String) {
                response = "Something went wrong, please try after time.";
            }
            this.setState({ errorMessage: response, loading: false });
        }
    };

    render() {
        const { classes, constants } = this.props;
        const { errorMessage, successMessage } = this.state;
        const { accountProperties, loading } = this.state;

        if (loading || !accountProperties) {
            return null;
        }

        return (
            <Formik
                initialValues={accountProperties}
                validate={validateProperties}
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
            >
                {({ values, handleSubmit, setFieldValue, errors }) => {
                    return (
                        <Form style={{ padding: "20px 10px" }}>
                            <Grid container spacing={3}>
                                <SelectBox
                                    md={4}
                                    name="updateFrequency"
                                    label="Update Frequency"
                                    items={constants.UPDATE_FREQUENCY}
                                />

                                <SelectBox
                                    md={4}
                                    name="theme"
                                    label="Theme"
                                    items={THEME}
                                />
                                <InputBox md={4} name="bucket" label="Bucket" />

                                <Grid item xs={12} md={2}>
                                    <CheckBox
                                        name="updateLocation"
                                        label={"Update Location"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CheckBox
                                        name="settings.debug"
                                        label={"Debug"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CheckBox
                                        name="settings.showAllLogs"
                                        label={"All Logs"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CheckBox
                                        name="settings.editLogs"
                                        label={"Edit Logs"}
                                    />
                                </Grid>
                                <InputBox
                                    md={6}
                                    name="apiKey.hereAppId"
                                    label="Here App Id"
                                />
                                <InputBox
                                    md={6}
                                    name="apiKey.hereApiKey"
                                    label="Here Api Key"
                                />
                            </Grid>
                            {successMessage && (
                                <Success message={successMessage} />
                            )}
                            {errorMessage && <Error message={errorMessage} />}
                            <div className={classes.DialogActions}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading ? "Saving..." : "SAVE"}
                                </Button>{" "}
                                <Button
                                    variant="contained"
                                    onClick={this.props.handleClose}
                                >
                                    CLOSE
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "accountData.accountCreateBusy", false),
        accountValues: _get(state, "form.accountForm.values", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAccountProperties,
            updateAccountProperties
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(EditAccountForm)
    )
);

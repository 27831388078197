import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getEstimates } from "../../actions/estimate/estimateActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import EstimatesTable from "./EstimatesTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Estimate extends React.Component {
    state = {
        open: false,
        estimates: [],
        loading: false
    };

    async componentDidMount() {
        await this.getEstimates();
    }

    handleOpen = () => {
        this.setState({ open: true, estimate: null });
    };

    getEstimates = async () => {
        try {
            this.setState({ loading: true });
            const estimates = await this.props.getEstimates();
            if (estimates && Array.isArray(estimates)) {
                this.setState({ estimates });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getEstimates();
    };

    handleEdit = estimate => {
        this.setState({ open: true, estimate });
    };

    render() {
        const { classes } = this.props;
        const { estimates, loading } = this.state;
        const { open, estimate } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        estimate={estimate}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <EstimatesTable
                        handleEdit={this.handleEdit}
                        items={estimates}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        estimates: _get(state, "estimateData.estimates", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getEstimates,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Estimate)
);

import React, { useState } from "react";
import { Field } from "formik";
import Grid from "@material-ui/core/Grid";
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const InputDateBoxMt = ({
    form: {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        ...rest
    },
    field: { name, value },
    ...props
}) => {
    const [selectedDate, handleDateChange] = useState(value);
    return (
        <KeyboardDateTimePicker
            {...props}
            value={selectedDate}
            onChange={e => {
                handleDateChange(e);
                setFieldValue([name], e);
            }}
        />
    );
};

const InputBox = ({
    name,
    label,
    type = "text",
    placeholder,
    disabled = false,
    onBlur = () => { },
    onChange = () => { },
    md = 4,
    xs = 12,
    value,
    format = "MM/DD/yyyy hh:mm A",
    minDate,
    maxDate
}) => {
    return (
        <Grid item md={md} xs={xs}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Field
                    type={type}
                    name={name}
                    label={label || name}
                    disabled={disabled}
                    component={InputDateBoxMt}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    format={format}
                    maxDate={maxDate}
                    minDate={minDate}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
};

export default InputBox;

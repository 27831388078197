import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ReduxButton({
    onClick,
    label,
    loading = false,
    width = 300,
    maxWidth = "100%",
    color = "primary",
    contained = "contained",
    styles = {}
}) {
    return (
        <Button
            disabled={loading}
            variant={contained}
            color={color}
            onClick={onClick}
            style={{ width, maxWidth, ...styles }}
        >
            {loading && (
                <CircularProgress size={20} style={{ marginRight: 5 }} />
            )}
            {label}
        </Button>
    );
}

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { updateProfile } from "../../actions/auth/authActions";
import Grid from "@material-ui/core/Grid";
import ReduxButton from "../../components/ReduxButton";
import Error from "../Error";
import Success from "../Success";
import { termsText } from "./termsText";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 40,
        justifyContent: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class TermsDialog extends React.Component {
    state = {
        successMessage: "",
        errorMessage: ""
    };

    componentDidMount() {}

    save = async () => {
        const response = await this.props.updateProfile({ terms: true });

        if (response.message) {
            this.setState({
                errorMessage: "Please try after sometime."
            });
        } else {
            this.props.onSuccess();
        }
    };

    decline = () => {
        this.setState({
            errorMessage:
                "Not able to continue as terms and conditions are not accepted."
        });
        setTimeout(() => this.props.decline(), 5000);
    };

    render() {
        const { classes, loading = false } = this.props;
        const { errorMessage, successMessage } = this.state;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <h2 style={{ textAlign: "center", padding: 0 }}>
                    {"Terms and Conditions"}
                </h2>
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.handleClose}
                >
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Typography>{termsText}</Typography>
                    <Grid container spacing={10}>
                        <Error top={20} message={errorMessage} />
                        <Success top={20} message={successMessage} />
                    </Grid>
                    <DialogActions className={classes.DialogActions}>
                        <ReduxButton
                            width
                            loading={loading}
                            onClick={this.save}
                            marginTop={25}
                            label={"Accept and continue"}
                        />
                        <Button onClick={this.decline} color="primary">
                            {"Decline"}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: _get(state, "authData.termsBusy", false),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "authData.errorMessage", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateProfile
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(TermsDialog)
    )
);

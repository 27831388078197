import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getCustomers } from "../../actions/customer/customerActions";
import { INVOICE_STATUS } from "../../constants";

const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        textAlign: "left"
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    },
    buttonGroup: {
        marginTop: 25,
        textAlign: "center"
    },
    button: {
        minWidth: 75
    },
    input: {}
});

const SearchBar = props => {
    const { classes, accountId } = props;

    const [searchStatus, setSearchStatus] = useState("");
    const [customerId, setCustomer] = useState("");
    const [customers, setCustomers] = useState([]);

    let isValid = searchStatus || customerId;

    useEffect(() => {
        async function fetchData() {
            const customers = await props.getCustomers({ skip: 0, take: 500 });
            setCustomers(customers);
        }

        fetchData();

        // eslint-disable-next-line
    }, [accountId]);

    const onSearch = e => {
        e.preventDefault();
        let payload = {
            searchStatus,
            customerId
        };
        props.onSearch(payload);
    };

    const onCancel = e => {
        e.preventDefault();

        setSearchStatus("");
        setCustomer("");

        let payload = {
            searchType: "",
            searchStatus: "",
            customerId: ""
        };
        props.onSearch(payload);
    };

    return (
        <div style={{ padding: 15 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={1}>
                    <Grid item sm xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="customerId">
                                Customer
                            </InputLabel>
                            <Select
                                className={classes.select}
                                value={customerId}
                                onChange={e => setCustomer(e.target.value)}
                            >
                                <MenuItem value={""}>Select</MenuItem>
                                {Array.isArray(customers) &&
                                    customers.map((item, i) => (
                                        <MenuItem key={i} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="customerId">Status</InputLabel>
                            <Select
                                className={classes.select}
                                value={searchStatus}
                                onChange={e => setSearchStatus(e.target.value)}
                            >
                                <MenuItem value={""}>Select</MenuItem>
                                {Array.isArray(INVOICE_STATUS) &&
                                    INVOICE_STATUS.map((item, i) => (
                                        <MenuItem key={i} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm xs={12}>
                        <div className={classes.buttonGroup}>
                            <ButtonGroup
                                size="small"
                                disableElevation
                                variant="contained"
                                color="primary"
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SearchIcon />}
                                    disabled={!isValid || props.loading}
                                    onClick={onSearch}
                                    ref={props.refrence}
                                    className={classes.button}
                                >
                                    {props.loading ? "Searching..." : "SEARCH"}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={onCancel}
                                    className={classes.button}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={props.addBtnClick}
                                    className={classes.button}
                                >
                                    ADD
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
    // }
};

const mapStateToProps = state => {
    return {
        drivers: _get(state, "driverData.drivers", []),
        customers: _get(state, "equipmentData.customers", []),
        accounts: _get(state, "accountData.accounts", []),
        constants: _get(state, "appData.constants", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ getCustomers }, dispatch);

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchBar)
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getRepairs } from "../../actions/repair/repairActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import RepairsTable from "./RepairsTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Repair extends React.Component {
    state = {
        open: false,
        repairs: [],
        loading: false
    };

    async componentDidMount() {
        await this.getRepairs();
    }

    handleOpen = () => {
        this.setState({ open: true, repair: null });
    };

    getRepairs = async () => {
        try {
            this.setState({ loading: true });
            const repairs = await this.props.getRepairs();
            if (repairs && Array.isArray(repairs)) {
                this.setState({ repairs });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getRepairs();
    };

    handleEdit = repair => {
        this.setState({ open: true, repair });
    };

    render() {
        const { classes } = this.props;
        const { repairs, loading } = this.state;
        const { open, repair } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        repair={repair}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <RepairsTable
                        handleEdit={this.handleEdit}
                        items={repairs}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        repairs: _get(state, "repairData.repairs", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getRepairs,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Repair)
);

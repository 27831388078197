const validate = values => {
    const errors = {};
    const requiredFields = ["password", "email"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `Required`;
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }
    return errors;
};

const validateForgetPwd = values => {
    const errors = {};
    const requiredFields = ["email"];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `Required`;
        }
    });
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }
    return errors;
};

const validateUpdatePwd = values => {
    const errors = {};

    if (!values.password) {
        errors.password = `Required`;
    } else if (values.password && values.password.length < 4) {
        errors.password = `Invalid password`;
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = `Required`;
    }

    if (
        values.password &&
        values.confirmPassword &&
        values.password !== values.confirmPassword
    ) {
        errors.confirmPassword = `Passwords do not match`;
    }
    return errors;
};

export { validateForgetPwd, validateUpdatePwd };
export default validate;

import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./estimateActionTypes";

export const setEstimates = value => ({
    type: TYPES.ESTIMATES,
    value
});

export const selectedEstimate = value => ({
    type: TYPES.SELECTED_ESTIMATE,
    value
});

export const selectedEstimateData = value => ({
    type: TYPES.SELECTED_ESTIMATE_DATA,
    value
});

export const estimatesCreateBusy = value => ({
    type: TYPES.ESTIMATES_CREATE_BUSY,
    value
});

export const estimatesBusy = value => ({
    type: TYPES.ESTIMATES_BUSY,
    value
});

export const estimatesAuthBusy = value => ({
    type: TYPES.ESTIMATES_AUTH_BUSY,
    value
});

export function createEstimate(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        dispatch(estimatesCreateBusy(true));
        return axios({
            url: `${BASE_URL}/secure/${accountId}/estimate`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(estimatesCreateBusy(false));
                return response;
            });
    };
}

export function updateEstimate(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/estimate`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getEstimatesCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/estimates/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setEstimates(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(estimatesBusy(false));
                return response;
            });
    };
}

export function getEstimates(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/estimate`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setEstimates(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(estimatesBusy(false));
                return response;
            });
    };
}

export function getEstimateDetails(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/estimates/${params.estimateId}`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(selectedEstimateData(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import SuccessIcon from "@material-ui/icons/Done";
import {
    downloadImage,
    getImageDownloadUrl
} from "../../actions/app/appActions";

class Download extends React.Component {
    state = {
        loading: false
    };

    handleDownload = async (e, media) => {
        this.setState({ loading: true, isError: false, isSuccess: false });

        const response = await this.props.downloadImage(media);
        // const response = await this.props.getImageDownloadUrl(media);

        this.setState({
            loading: false,
            isError: response === "Bad Request",
            isSuccess: response !== "Bad Request"
        });
    };

    render() {
        const { loading, isError = false, isSuccess = false } = this.state;
        const media = this.props.media || {};
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: 100 }}
                    disabled={!media.name}
                    onClick={e => this.handleDownload(e, media)}
                >
                    {isError && <CloseIcon color="error" />}
                    {isSuccess && <SuccessIcon />}
                    {loading ? "Downloading..." : "Download"}
                </Button>
                {isError && (
                    <div style={{ color: "red", fontSize: 10 }}>
                        Not able to download
                    </div>
                )}
                {!media.name && (
                    <div style={{ color: "red", fontSize: 10 }}>
                        Missing file
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ downloadImage, getImageDownloadUrl }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Download);

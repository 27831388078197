import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
    createCustomer,
    updateCustomer
} from "../../actions/customer/customerActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
// import { ALL_CUSTOMER_TYPES, ALL_STATUS } from "../../constants";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {}

    handleSubmit = async values => {
        // console.log(values);

        const customer = this.props.customer || {};

        this.setState({ loading: true });
        try {
            const address = values.address || {};
            const contact = values.contact || {};
            const payload = {
                accountId: this.props.accountId,
                name: values.name,
                email: values.email,
                status: values.status,
                type: values.type,
                dot: values.dot,
                mc: values.mc,
                ein: values.ein,
                address: address,
                contact: contact
            };

            let response;
            if (customer && customer.id) {
                payload.id = customer.id;
                response = await this.props.updateCustomer(payload);
            } else {
                response = await this.props.createCustomer(payload);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes, constants } = this.props;
        const customer = this.props.customer || {};
        const { loading, errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            name: "",
            email: "",
            status: "ACTIVE",
            type: "CARRIER",
            dot: "",
            mc: "",
            ein: "",
            address: {
                country: "US",
                line1: "",
                line2: "",
                city: "",
                state: "",
                zipCode: ""
            },
            contact: {
                type: "",
                name: "",
                phone: "",
                email: ""
            },
            ...customer
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {customer.id ? "EDIT CUSTOMER" : "ADD CUSTOMER"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            console.log(errors);
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <InputBox
                                            md={4}
                                            name="name"
                                            label="Name"
                                        />
                                        <InputBox
                                            md={4}
                                            name="email"
                                            label="Email"
                                        />
                                        {/*<SelectBox
                                            md={2}
                                            name="type"
                                            label="Type"
                                            selLabel={"id"}
                                            items={constants.ALL_CUSTOMER_TYPES}
                                        />*/}
                                        <SelectBox
                                            md={4}
                                            name="status"
                                            label="Status"
                                            selLabel={"id"}
                                            items={constants.ALL_STATUS}
                                        />
                                        <InputBox name="dot" label="DOT" />
                                        <InputBox name="mc" label="MC" />
                                        <InputBox name="ein" label="EIN" />

                                        <SelectBox
                                            md={2}
                                            name="address.country"
                                            label="Country"
                                            selLabel={"id"}
                                            selValue="id"
                                            items={constants.COUNTRIES}
                                        />
                                        <InputBox
                                            md={2}
                                            name="address.line1"
                                            label="Address line1"
                                        />
                                        <InputBox
                                            md={2}
                                            name="address.line2"
                                            label="Suit., bldg."
                                        />
                                        <InputBox
                                            md={2}
                                            name="address.city"
                                            label="City"
                                        />
                                        {values.country === "CA" ? (
                                            <SelectBox
                                                md={2}
                                                name="address.state"
                                                label="State"
                                                selLabel={"id"}
                                                items={constants.CA_STATES}
                                            />
                                        ) : (
                                            <SelectBox
                                                md={2}
                                                name="address.state"
                                                label="State"
                                                selLabel={"id"}
                                                items={constants.US_STATES}
                                            />
                                        )}
                                        <InputBox
                                            md={2}
                                            name="address.zipCode"
                                            label="Zip"
                                        />

                                        <InputBox
                                            name="contact.name"
                                            label="Contact name"
                                        />
                                        <InputBox
                                            name="contact.email"
                                            label="Email"
                                        />
                                        <InputBox
                                            md={2}
                                            name="contact.phone"
                                            label="Phone"
                                        />

                                        <InputBox
                                            md={2}
                                            name="contact.type"
                                            label="Role"
                                        />

                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                        <DialogActions
                                            className={classes.DialogActions}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? "Saving..." : "SAVE"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={this.props.handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createCustomer,
            updateCustomer
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);

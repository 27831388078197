import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./repairActionTypes";

export const setRepairs = value => ({
    type: TYPES.REPAIRS,
    value
});

export const selectedRepair = value => ({
    type: TYPES.SELECTED_REPAIR,
    value
});

export const selectedRepairData = value => ({
    type: TYPES.SELECTED_REPAIR_DATA,
    value
});

export const repairsCreateBusy = value => ({
    type: TYPES.REPAIRS_CREATE_BUSY,
    value
});

export const repairsBusy = value => ({
    type: TYPES.REPAIRS_BUSY,
    value
});

export const repairsAuthBusy = value => ({
    type: TYPES.REPAIRS_AUTH_BUSY,
    value
});

export function createRepair(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        dispatch(repairsCreateBusy(true));
        return axios({
            url: `${BASE_URL}/secure/${accountId}/repair`,
            method: "post",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(repairsCreateBusy(false));
                return response;
            });
    };
}

export function updateRepair(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/repair`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}

export function getRepairsCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/repairs/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setRepairs(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(repairsBusy(false));
                return response;
            });
    };
}

export function getRepairs(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/repair`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setRepairs(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(repairsBusy(false));
                return response;
            });
    };
}

export function getRepairDetails(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/admin/repairs/${params.repairId}`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(selectedRepairData(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


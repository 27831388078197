import _get from "lodash/get";
import * as TYPES from "../auth/authActionTypes";
const defaultState = {
    appBusy: false,
    preCheck: true,
    isAdmin: true,
    isPartner: true,
    isManager: true,
    isMechanic: true,
    isCustomer: true,
    isGuest: true,
    termsBusy: false,
    needAutorization: false,
    accountBaseUrl: ""
};

function filerRoles(user) {
    if (user.roles && Array.isArray(user.roles)) {
        return user.roles.map(item => _get(item, "userRole.name"));
    } else {
        return [];
    }
}

function filerScopes(user) {
    if (user.roles && Array.isArray(user.roles)) {
        return user.scopes.map(item => _get(item, "userScope.name"));
    } else {
        return [];
    }
}

export default function auth(state = defaultState, action) {
    switch (action.type) {
        case "PROFILE":
            const roles = filerRoles(action.value);
            const scopes = filerScopes(action.value);

            const isAdmin = roles.includes("ADMIN");
            const isManager = roles.includes("MANAGER");
            const isPartner = roles.includes("PARTNER");
            const isMechanic = roles.includes("MECHANIC");
            const isCustomer = roles.includes("CUSTOMER");
            const isGuest = roles.includes("GUEST");
            const account = _get(action, "value.account");
            const accountId = _get(action, "value.accountId");
            const customerId = _get(action, "value.customerId");
            const adminAccess = _get(action, "value.user.adminAccess", false);

            return {
                ...state,
                isAdmin,
                isPartner,
                isManager,
                isMechanic,
                isCustomer,
                isGuest,
                account,
                profile: {
                    ...action.value,
                    roles,
                    scopes,
                    account: null
                },
                accountId,
                customerId,
                adminAccess,
                needAutorization: false,
                accountBaseUrl: `/dashboard/${accountId}`
            };
        case "SIGNIN_ERROR":
            return {
                ...state,
                signInError: action.value
            };
        case "NEED_AUTORIZATION":
            return {
                ...state,
                needAutorization: action.value
            };
        case TYPES.TERMS_BUSY:
            return {
                ...state,
                termsBusy: action.value
            };
        default:
            return state;
    }
}

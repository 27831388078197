import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAccountAuth } from "../../actions/account/accountActions";
import { getProfile } from "../../actions/auth/authActions";
import { updateUserAccount } from "../../actions/account/accountActions";
import { getAccountDetails } from "../../actions/account/accountActions";
import UpdateButton from "./UpdateButton";

import * as cookie from "react-cookie";
const cookies = new cookie.Cookies();

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    statusButton: {
        margin: "0 2vh 0 2vh",
        float: "right"
    }
});

function RenderDriver({
    user = {},
    handleLogin,
    accountAuthBusy,
    updateUserAccount,
    getAccountDetails,
    loading
}) {
    const status = _get(user, "status", "");
    let active = false;
    if (status === "ACTIVE") {
        active = true;
    } else {
        active = false;
    }

    return (
        <Grid item xs={12} md={6}>
            <Paper style={{ marginTop: 10, padding: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <h3>
                            {user.firstName} {user.lastName}
                            <div>
                                <small>{user.email}</small>
                            </div>
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={accountAuthBusy || loading || !active}
                            style={{ margin: "2vh", float: "right" }}
                            onClick={e => handleLogin(user)}
                        >
                            Log In
                        </Button>
                        <UpdateButton
                            active={active}
                            accountAuthBusy={accountAuthBusy}
                            user={user}
                            updateUserAccount={updateUserAccount}
                            getAccountDetails={getAccountDetails}
                        />
                    </Grid>
                    <Grid item xs={12} />
                </Grid>
            </Paper>
        </Grid>
    );
}

export class ViewAccountDetail extends React.Component {
    state = {};

    componentDidMount() {}

    handleLogin = async user => {
        this.setState({ loading: true });

        const accountId = _get(this, "props.selectedAccount._id");
        const carrierId = _get(this, "props.selectedAccount.carrierId");
        const terminalId = _get(this, "props.selectedAccount.terminalId");

        const response = await this.props.getAccountAuth({
            accountId,
            carrierId,
            terminalId,
            userId: user.id
        });

        cookies.set("token", response.token, {
            path: "/",
            maxAge: 43200
        });

        await this.props.getProfile();

        setTimeout(() => {
            const { accountBaseUrl } = this.props;
            // console.log("handleLogin accountBaseUrl ::::: ", accountBaseUrl);
            // const accountBaseUrl = `/dashboard/${accountId}`;
            this.props.history.push(`${accountBaseUrl}/status/view`);
        }, 500);
        this.setState({ loading: false });

        // console.log("handleLogin accountBaseUrl ::::: ", accountBaseUrl);
        // // const accountBaseUrl = `/dashboard/${accountId}`;
        // this.props.history.push(`${accountBaseUrl}/status/view`);

        // console.log("rhandleLogin esponse ::::: ", response);
        // if (response.token) {
        //     const url = `https://web.eldbooks.com?token=${response.token}`;
        //     window.open(url, "_blank");
        //     this.props.handleClose();
        // }
    };

    render() {
        const {
            classes,
            selectedAccount,
            selectedAccountData,
            accountAuthBusy,
            loading
            // accountBaseUrl
        } = this.props;
        let adminStatus = {
            activeDriver: 0,
            inactiveDriver: 0,
            activeUser: 0,
            inactiveUser: 0
        };
        let equipStatus = {
            activeVehicle: 0,
            inactiveVehicle: 0
        };
        const isSmallScreen = window.innerWidth < 400;
        if (!selectedAccount) {
            return null;
        }
        const { admins = [], equipments = [] } = selectedAccountData;

        Array.isArray(equipments) &&
            equipments.forEach(item => {
                if (item.assetType === "VEHICLE") {
                    if (item.active) {
                        equipStatus.activeVehicle += 1;
                    } else {
                        equipStatus.inactiveVehicle += 1;
                    }
                }
                // else {
                //     if (item.active) {
                //         equipStatus.activeTrailer += 1;
                //     }
                //     else {
                //         equipStatus.inactiveTrailer += 1;
                //     }
                // }
            });

        Array.isArray(admins) &&
            admins.forEach(item => {
                if (item.role === "DRIVER") {
                    if (item.status === "ACTIVE") {
                        adminStatus.activeDriver += 1;
                    } else {
                        adminStatus.inactiveDriver += 1;
                    }
                } else {
                    if (item.status === "ACTIVE") {
                        adminStatus.activeUser += 1;
                    } else {
                        adminStatus.inactiveUser += 1;
                    }
                }
            });

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography style={{ textAlign: "center" }}>
                        <span style={{ marginTop: 0 }}>
                            {selectedAccount.name}
                        </span>
                    </Typography>
                    <IconButton
                        style={{ marginTop: 5 }}
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <span>{`Manager: ${adminStatus.activeUser +
                                        adminStatus.inactiveUser}`}</span>
                                    <span
                                        style={{ display: "block" }}
                                    >{`Active: ${
                                        adminStatus.activeUser
                                    }`}</span>
                                    <span>{`Inactive: ${
                                        adminStatus.inactiveUser
                                    }`}</span>
                                </Grid>

                                <Grid item xs={4}>
                                    <span>{`Driver: ${adminStatus.activeDriver +
                                        adminStatus.inactiveDriver}`}</span>
                                    <span
                                        style={{ display: "block" }}
                                    >{`Active: ${
                                        adminStatus.activeDriver
                                    }`}</span>
                                    <span>{`Inactive: ${
                                        adminStatus.inactiveDriver
                                    }`}</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>{`Vehicle: ${equipStatus.activeVehicle +
                                        equipStatus.inactiveVehicle}`}</span>
                                    <span
                                        style={{ display: "block" }}
                                    >{`Active: ${
                                        equipStatus.activeVehicle
                                    }`}</span>
                                    <span>{`Inactive: ${
                                        equipStatus.inactiveVehicle
                                    }`}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {admins.map((user, i) => {
                                    if (user.role !== "MANAGER") {
                                        return null;
                                    }
                                    return (
                                        <RenderDriver
                                            accountAuthBusy={accountAuthBusy}
                                            handleLogin={this.handleLogin}
                                            classes={classes}
                                            key={i}
                                            user={user}
                                            changeStatus={this.changeStatus}
                                            loading={this.state.loading}
                                            getAccountDetails={
                                                this.props.getAccountDetails
                                            }
                                            updateUserAccount={
                                                this.props.updateUserAccount
                                            }
                                            handleClose={this.props.handleClose}
                                        />
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        selectedAccount: _get(state, "accountData.selectedAccount", {}),
        accountAuthBusy: _get(state, "accountData.accountAuthBusy", false),
        selectedAccountData: _get(state, "accountData.selectedAccountData", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getAccountAuth,
            getProfile,
            updateUserAccount,
            getAccountDetails
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ViewAccountDetail)
    )
);

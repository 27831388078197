import _get from "lodash/get";
import _set from "lodash/set";

const validate = values => {
    const errors = {};

    if (!values.type) {
        errors.type = "Required";
    }
    if (!values.status) {
        errors.status = "Required";
    }
    if (!values.customerId) {
        errors.customerId = "Required";
    }

    return errors;
};

const validateEdit = values => {
    const errors = {};
    const requiredFields = [
        "status",
    ];
    requiredFields.forEach(field => {
        let _value = _get(values, `${field}`);
        if (!_value) {
            _set(errors, `${field}`, "Required");
        }
    });

    const contactEmail = values.contact && values.contact.email;
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (
        contactEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)
    ) {
        _set(errors, `contact.email`, "Invalid email address");
    }
    return errors;
};

const validateProperties = values => {
    const errors = {};

    if (!values.bucket) {
        errors.bucket = "Required";
    }

    return errors;
};

export { validate, validateEdit, validateProperties };
export default validate;

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { createUser, updateUser } from "../../actions/userActions";
import { getCustomers } from "../../actions/customer/customerActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false,
        customers: []
    };

    componentDidMount() {
        // this.getCustomers();
    }

    getCustomers = async () => {
        const customers = await this.props.getCustomers({ skip: 0, take: 500 });
        this.setState({ customers });
    };

    handleSubmit = async values => {
        // console.log(values);

        const user = this.props.user || {};

        this.setState({ loading: true });
        try {
            const payload = {
                accountId: this.props.accountId,
                customerId: values.customerId,
                name: values.name,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                role: [values.role],
                scope: [values.scope]
            };


            let response;
            if (user && user.id) {
                payload.id = user.id;
                response = await this.props.updateUser(payload);
            } else {
                response = await this.props.createUser(payload);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes, constants } = this.props;
        const user = this.props.user || {};
        const customers = this.state.customers || [];
        const { loading, errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: "",
            scope: "",
            ...user
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {user.id ? "EDIT USER" : "ADD USER"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            console.log(errors);
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <InputBox
                                            md={4}
                                            name="firstName"
                                            label="First Name"
                                        />
                                        <InputBox
                                            md={4}
                                            name="lastName"
                                            label="Last Name"
                                        />
                                        <InputBox
                                            md={4}
                                            name="email"
                                            label="Email"
                                        />
                                        <InputBox
                                            md={4}
                                            name="password"
                                            label="Password"
                                        />
                                        <SelectBox
                                            md={4}
                                            name="role"
                                            label="Role"
                                            selLabel={"id"}
                                            items={constants.USER_ROLES}
                                        />
                                        <SelectBox
                                            md={4}
                                            name="scope"
                                            label="Scope"
                                            selLabel={"id"}
                                            items={constants.USER_SCOPES}
                                        />
                                        <SelectBox
                                            md={4}
                                            name="customerId"
                                            label="Customer"
                                            selLabel={"name"}
                                            items={customers}
                                        />
                                    </Grid>
                                    <Grid container spacing={3}>

                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                    </Grid>
                                    <Grid container spacing={3}>

                                        <DialogActions
                                            className={classes.DialogActions}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? "Saving..." : "SAVE"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={this.props.handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createUser,
            updateUser,
            getCustomers
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);

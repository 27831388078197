import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { getInvoices } from "../../actions/invoice/invoiceActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import InvoicesTable from "./InvoiceTable";
import SearchBar from "./SearchBar";
import AddForm from "./AddForm";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Invoice extends React.Component {
    state = {
        open: false,
        invoices: [],
        loading: false,
        limit: 25,
        page: 0
    };

    async componentDidMount() {
        await this.getInvoices();
    }

    handleOpen = () => {
        this.setState({ open: true, invoice: null });
    };

    getInvoices = async () => {
        try {
            this.setState({ loading: true });

            const page = parseInt(this.state.page);
            const limit = parseInt(this.state.limit);
            const skip = page * limit;
            let params = {
                take: limit,
                skip,
                relations: ["customer"]
            };

            if (this.state.customerId) {
                params.customerId = this.state.customerId;
            }

            if (this.state.searchStatus) {
                params.status = this.state.searchStatus;
            }

            const invoices = await this.props.getInvoices(params);
            if (invoices && Array.isArray(invoices)) {
                this.setState({ invoices });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getInvoices();
    };

    handleEdit = invoice => {
        this.setState({ open: true, invoice });
    };

    onSearch = ({ searchStatus, customerId }) => {
        this.setState({ searchStatus, customerId }, () => {
            this.getInvoices();
        })
    };

    showMessageDialog = () => { };

    render() {
        const { classes, accountBaseUrl } = this.props;
        const { invoices, loading } = this.state;
        const { open, invoice } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        invoice={invoice}
                        handleClose={this.handleClose}
                    />
                )}

                <SearchBar
                    onSearch={this.onSearch}
                    showMessageDialog={this.showMessageDialog}
                    loading={loading}
                    addBtnClick={this.handleOpen}
                />
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <InvoicesTable
                        handleEdit={this.handleEdit}
                        items={invoices}
                        formatDate={this.props.formatDate}
                        accountBaseUrl={accountBaseUrl}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        invoices: _get(state, "invoiceData.invoices", []),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInvoices,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Invoice)
);

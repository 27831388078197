import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getCustomers } from "../../actions/customer/customerActions";
import {
    createEquipment,
    updateEquipment
} from "../../actions/equipment/equipmentActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
// import { ALL_STATUS, EQP_TYPES } from "../../constants";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false,
        customers: []
    };

    async componentDidMount() {
        await this.getCustomers();
    }

    getCustomers = async () => {
        try {
            if (this.props.isManager) {
                const customers = await this.props.getCustomers();
                if (customers && Array.isArray(customers)) {
                    this.setState({ customers });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    handleSubmit = async values => {
        // console.log(values);

        const equipment = this.props.equipment || {};

        this.setState({ loading: true });
        try {
            const detail = values.detail || {};
            const license = values.license || {};
            const payload = {
                accountId: this.props.accountId,
                customerId: values.customerId,
                name: values.name,
                alias: values.alias,
                vin: values.vin,
                status: values.status,
                type: values.type,
                detail: detail,
                license: license
            };

            let response;
            if (equipment && equipment.id) {
                payload.id = equipment.id;
                response = await this.props.updateEquipment(payload);
            } else {
                response = await this.props.createEquipment(payload);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes, isManager, constants } = this.props;
        const equipment = this.props.equipment || {};
        const customers = this.state.customers || [];
        const { loading, errorMessage, successMessage } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            name: "",
            status: "ACTIVE",
            type: "TRAILER",
            customerId: "",
            vin: "",
            detail: {
                mfr: "",
                mdl: "",
                yr: ""
            },
            license: {
                country: "",
                plt: "",
                sta: "",
                exp: ""
            },
            ...equipment
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {equipment.id ? "EDIT EQUIPMENT" : "ADD EQUIPMENT"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            values.isManager = isManager;
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        {isManager && (
                                            <SelectBox
                                                md={3}
                                                name="customerId"
                                                label="Customer"
                                                selLabel={"name"}
                                                selValue={"id"}
                                                items={customers}
                                            />
                                        )}
                                        <InputBox
                                            md={3}
                                            name="name"
                                            label="Name"
                                        />
                                        <InputBox
                                            md={3}
                                            name="alias"
                                            label="alias"
                                        />
                                        <SelectBox
                                            md={3}
                                            name="type"
                                            label="Type"
                                            selLabel={"id"}
                                            items={constants.EQP_TYPES}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="status"
                                            label="Status"
                                            selLabel={"id"}
                                            items={constants.ALL_STATUS}
                                        />

                                        <InputBox
                                            md={3}
                                            name="detail.mfr"
                                            label="Manufacturer"
                                        />
                                        <InputBox
                                            md={3}
                                            name="detail.mdl"
                                            label="Model"
                                        />
                                        <InputBox
                                            md={3}
                                            name="detail.yr"
                                            label="Year"
                                        />

                                        <SelectBox
                                            md={3}
                                            name="license.country"
                                            label="Country"
                                            selLabel={"id"}
                                            selValue="id"
                                            items={constants.COUNTRIES}
                                        />
                                        <InputBox
                                            md={2}
                                            name="license.plt"
                                            label="Lic Plate"
                                        />
                                        {values.country === "CA" ? (
                                            <SelectBox
                                                md={2}
                                                name="license.sta"
                                                label="Lic State"
                                                selLabel={"id"}
                                                items={constants.CA_STATES}
                                            />
                                        ) : (
                                            <SelectBox
                                                md={2}
                                                name="license.sta"
                                                label="Lic State"
                                                selLabel={"id"}
                                                items={constants.US_STATES}
                                            />
                                        )}
                                        <InputBox
                                            md={2}
                                            name="license.exp"
                                            label="Lic Expiry"
                                        />
                                        <InputBox
                                            md={3}
                                            name="vin"
                                            label="Vin"
                                        />

                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                        <DialogActions
                                            className={classes.DialogActions}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? "Saving..." : "SAVE"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={this.props.handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET"),
        isManager: _get(state, "authData.isManager")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCustomers,
            createEquipment,
            updateEquipment
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { passwordResetUpdate } from "../../actions/auth/authActions";
import Grid from "@material-ui/core/Grid";
import Error from "../Error";
import Success from "../Success";
import { validateUpdatePwd } from "./validate";
import { Formik, Form } from "formik";
import InputBox from "../../components/InputBox";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 40,
        justifyContent: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

export class TermsDialog extends React.Component {
    state = {
        email: "",
        successMessage: "",
        errorMessage: ""
    };

    componentDidMount() { }

    onResetRequest = async ({ password }) => {
        this.setState({ loading: true });

        const { token, userId } = this.props;
        const params = { password, token, userId };
        const response = await this.props.passwordResetUpdate(params);

        if (response.status && response.status === "SUCCESS") {
            this.setState({
                loading: false,
                successMessage: "Password is successfully updated"
            });
            setTimeout(() => {
                window.location.href = "/";
            }, 5000);
        } else if (response.status && response.status === "ERROR") {
            this.setState({
                loading: false,
                errorMessage: response.message
            });
        } else {
            this.setState({
                loading: false,
                errorMessage: "Please try after sometime."
            });
        }
    };

    render() {
        const { classes } = this.props;
        const { errorMessage, successMessage, loading } = this.state;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <h2 style={{ textAlign: "center", padding: 0 }}>
                    {"Update password"}
                </h2>
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.props.onClose}
                >
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validate={validateUpdatePwd}
                        onSubmit={this.onResetRequest}
                    >
                        {({ values, handleSubmit, setFieldValue }) => {
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <InputBox
                                            md={12}
                                            name="password"
                                            label="Enter new Password"
                                        />
                                        <InputBox
                                            md={12}
                                            name="confirmPassword"
                                            label="Confirm new Password"
                                        />
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Error
                                            top={20}
                                            message={errorMessage}
                                        />
                                        <Success
                                            top={20}
                                            message={successMessage}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        style={{
                                            marginTop: 45,
                                            textAlign: "center"
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            style={{
                                                minWidth: 250
                                            }}
                                        >
                                            {loading
                                                ? "Loading..."
                                                : "Update Password"}
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        style={{
                                            marginTop: 15,
                                            textAlign: "center"
                                        }}
                                    >
                                        <Button onClick={this.props.onClose}>
                                            {"Close"}
                                        </Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: _get(state, "authData.termsBusy", false),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "authData.errorMessage", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            passwordResetUpdate
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(TermsDialog)
    )
);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    selectedAccount,
    createAccount,
    updateAccount,
    getAccounts,
    getAccountsCount,
    getAccountDetails
} from "../../actions/account/accountActions";
import { formatDate } from "../../actions/momentActions";
import AccountTable from "./AccountTable";
import AddAccountForm from "./Forms/AddAccountForm";
import EditAccount from "./Forms/EditAccount";
import Button from "@material-ui/core/Button";
import SearchBar from "./SearchBar";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    }
});

export class Carrier extends React.Component {
    state = {
        open: false,
        carrier: null,
        selected: null,
        limit: 25,
        page: 0,
        accountCount: 0
    };

    async componentDidMount() {
        // await this.getAccountsCount();
        await this.getAccounts();
    }

    getAccountsCount = async () => {
        const response = await this.props.getAccountsCount({});
        if (response && response.count) {
            this.setState({ accountCount: response.count || 0 });
        }
    };

    getAccounts = async query => {
        const { limit, page } = this.state;
        const skip = parseInt(page) * parseInt(limit);
        const accountOrg = await this.props.getAccounts({
            limit,
            skip,
            query
        });
        this.setState({ accounts: accountOrg, accountOrg, loading: false });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, selected: null });
    };

    handleEdit = selected => {
        this.setState({ isEdit: true, selected });
    };

    handleEditClose = () => {
        this.setState({ isEdit: false, selected: null });
        this.getAccounts();
    };
    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getAccounts);
    };

    timeOut = null;

    onSearchChange = event => {
        const query = event.target.value;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
            this.getAccounts(query);
            this.timeOut = null;
        }, 1000);

        // const accounts =
        //     this.state.accountOrg.filter(item => {
        //         if (item.name.toUpperCase().includes(query.toUpperCase())) {
        //             return true;
        //         }
        //         return false;
        //     }) || [];
        // this.setState({ accounts });
    };

    handleAccountDetails = account => {
        this.props.history.push(`/dashboard/accounts/${account._id}`);
    };

    render() {
        const { classes } = this.props;
        const accounts = this.state.accounts;
        const { open, selected, isEdit, loading } = this.state;
        return (
            <div>
                {open && (
                    <AddAccountForm
                        open={open}
                        createAccount={this.props.createAccount}
                        getAccounts={this.props.getAccounts}
                        handleClose={this.handleClose}
                    />
                )}
                {isEdit && (
                    <EditAccount
                        open={isEdit}
                        selected={selected}
                        updateAccount={this.props.updateAccount}
                        getAccounts={this.props.getAccounts}
                        handleClose={this.handleEditClose}
                    />
                )}
                <Grid container className={classes.addWrapper}>
                    <Grid item xs={12} sm={12} md={2}>
                        <span className={classes.searchText}>
                            {" "}
                            Count : {accounts && accounts.length}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <SearchBar onChange={this.onSearchChange} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        style={{ textAlign: "right" }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleOpen}
                        >
                            {"ADD"}
                        </Button>
                    </Grid>
                </Grid>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <AccountTable
                            items={accounts}
                            count={this.state.accountCount}
                            handleEdit={this.handleEdit}
                            formatDate={this.props.formatDate}
                            handleChange={this.handleChange}
                            limit={this.state.limit}
                            page={this.state.page}
                            selectedAccount={this.props.selectedAccount}
                            getAccountDetails={this.props.getAccountDetails}
                            handleAccountDetails={this.handleAccountDetails}
                        />
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        carrierBusy: _get(state, "carrierData.carrierBusy", false),
        accounts: _get(state, "accountData.accounts", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createAccount,
            updateAccount,
            selectedAccount,
            getAccounts,
            getAccountsCount,
            getAccountDetails,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Carrier)
);

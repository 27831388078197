import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./customerActionTypes";

export const setCustomers = value => ({
    type: TYPES.CUSTOMERS,
    value
});

export function getCustomerCount(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/customer/count`,
                method: "get",
                params
            });

            const response = _get(_response, "data", {});
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function getCustomers(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/customer`,
                method: "get",
                params
            });

            const response = _get(_response, "data", []);

            dispatch(setCustomers(response));
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function createCustomer(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/customer`,
                timeout: 20000,
                method: "post",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function updateCustomer(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/customer`,
                timeout: 20000,
                method: "put",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

import firebase from "firebase";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

export const getToken = () => {
    const messaging = firebase.messaging();
    return messaging
        .getToken({
            vapidKey: process.env.REACT_APP_FIREBASE_CERT
        })
        .then(currentToken => {
            if (currentToken) {
                return currentToken;
            } else {
                console.log("No token. Request permission to generate one.");
                return;
            }
        })
        .catch(err => {
            console.log("An error occurred while retrieving token. ", err);
        });
};

export const onMessageListener = () => {
    return new Promise(resolve => {
        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging.onMessage(payload => {
                resolve(payload);
            });
        }
    });
}

export const fMessaging = firebase.messaging
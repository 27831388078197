import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../NotFoundTableRow";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        return (
            <TableRow hover>
                <TableCell> {item.customerId} </TableCell>
                <TableCell> {item.status} </TableCell>
                <TableCell> {item.type} </TableCell>
                <TableCell> {item.notes} </TableCell>
                <TableCell> {item.createdTm} </TableCell>
                <TableCell>
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={e => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class CarrierTable extends React.Component {
    render() {
        const { eld, classes, items = [], handleEdit } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    eld={eld}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
            </Paper>
        );
    }
}

CarrierTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CarrierTable);

import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getTodos } from "../../actions/todo/todoActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TodosTable from "./TodosTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Todo extends React.Component {
    state = {
        open: false,
        todos: [],
        loading: false
    };

    async componentDidMount() {
        await this.getTodos();
    }

    handleOpen = () => {
        this.setState({ open: true, todo: null });
    };

    getTodos = async () => {
        try {
            this.setState({ loading: true });
            const todos = await this.props.getTodos();
            if (todos && Array.isArray(todos)) {
                this.setState({ todos });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getTodos();
    };

    handleEdit = todo => {
        this.setState({ open: true, todo });
    };

    render() {
        const { classes } = this.props;
        const { todos, loading } = this.state;
        const { open, todo } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        todo={todo}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TodosTable
                        handleEdit={this.handleEdit}
                        items={todos}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        todos: _get(state, "todoData.todos", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTodos,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Todo)
);

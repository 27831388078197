const defaultState = {
    count: 0,
    equipments: []
};

export default function equipment(state = defaultState, action) {
    switch (action.type) {
        case "EQUIPMENTS":
            return {
                ...state,
                equipments: action.value
            };
        case "EQUIPMENT_COUNT":
            return {
                ...state,
                count: action.value
            };
        default:
            return state;
    }
}

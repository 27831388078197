import _get from "lodash/get";
const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
    config._d = new Date(config._i);
};

const defaultTimeZone = moment.tz.guess();

export function formatRawDate(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            // return moment.tz(date, timezone).format("MM/D/YYYY");
            return moment.tz(date, timezone).format("YYYY/MM/DD");
        } else {
            return null;
        }
    };
}

export function formatDate(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment.tz(date, timezone).format("MMM Do YYYY");
        } else {
            return null;
        }
    };
}

export function formatDateTime(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment.tz(date, timezone).format("MMM Do YYYY @ hh:mm A");
        } else {
            return null;
        }
    };
}

export function getDate(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment.tz(date, timezone)
        } else {
            return null;
        }
    };
}


export function formatDateTimeSec(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment.tz(date, timezone).format("MMMM Do YYYY, h:mm:ss a");
        } else {
            return null;
        }
    };
}

export function formatTime(date) {
    return (dispatch, getState) => {
        if (moment(date).isValid()) {
            const timezone = _get(
                getState(),
                "appData.timezone",
                defaultTimeZone
            );
            return moment.tz(date, timezone).format("hh:mm A");
            // return moment(date).format("hh:mm A");
        } else {
            return null;
        }
    };
}

export function formatDateTimeUtc(date) {
    return (dispatch, getState) => {
        if (moment(date).isValid()) {
            const timezone = _get(
                getState(),
                "appData.timezone",
                defaultTimeZone
            );
            return moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss");
            // return moment(date).format("hh:mm A");
        } else {
            return null;
        }
    };
}

export function formatDateTimeToUtc(date) {
    return (dispatch, getState) => {
        if (moment(date).isValid()) {
            let fmt = "YYYY-MM-DDTHH:mm.sssZ";
            const timezone = _get(
                getState(),
                "appData.timezone",
                defaultTimeZone
            );
            return moment.tz(date, fmt, timezone).toISOString();
            // return moment(date).format("hh:mm A");
        } else {
            return null;
        }
    };
}

export function getTimeZone() {
    return (dispatch, getState) => {
        return moment.tz.guess();
    };
}

export function isToday(date) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment.tz(timezone).isSame(date, "day");
        } else {
            return null;
        }
    };
}

export function addDay(date, days = 1) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment
                .tz(date, timezone)
                .add(days, "days")
                .format("MM/D/YYYY");
        } else {
            return null;
        }
    };
}

export function addYears(date, days = 1) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment
                .tz(date, timezone)
                .add(1, "years")
                .format("MM/D/YYYY");
        } else {
            return null;
        }
    };
}

export function subtractDay(date, days = 1) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment
                .tz(date, timezone)
                .subtract(days, "day")
                .format("MM/D/YYYY");
        } else {
            return null;
        }
    };
}

export function getDailyLogsDays(date, days = 30) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return {
                to: moment.tz(date, timezone).endOf("day"),
                from: moment
                    .tz(date, timezone)
                    .subtract(days, "day")
                    .startOf("day"), // TODO: - will think if increase or decrease

                endOfDay: moment.tz(date, timezone).endOf("day"),
                startOfDay: moment.tz(date, timezone).startOf("day")
            };
        } else {
            return null;
        }
    };
}

export function buildDate(date, time) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        return moment.tz(`${date} ${time}`, timezone);
    };
}

export function getStartOfDay(date, hour = 0) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment
                .tz(`${date}`, timezone)
                .startOf("day")
                .add(hour, "hours");
        } else {
            return null;
        }
    };
}

export function getEndOfDay(date, hour = 0) {
    return (dispatch, getState) => {
        const timezone = _get(getState(), "appData.timezone", defaultTimeZone);
        if (moment(date).isValid()) {
            return moment
                .tz(`${date}`, timezone)
                .endOf("day")
                .add(hour, "hours");
        } else {
            return null;
        }
    };
}

import _get from "lodash/get";
import _set from "lodash/set";

const validate = values => {
    const errors = {};

    if (!values.name) {
        errors.name = "Required";
    }
    if (!values.email) {
        errors.email = "Required";
    } else {
        if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address";
        }
    }
    if (!values.dot) {
        errors.dot = "Required";
    }
    if (!values.address.line1) {
        errors.address = errors.address || {};
        errors.address.line1 = "Required";
    }
    if (!values.address.city) {
        errors.address = errors.address || {};
        errors.address.city = "Required";
    }
    if (!values.address.state) {
        errors.address = errors.address || {};
        errors.address.state = "Required";
    }
    if (!values.address.zipCode) {
        errors.address = errors.address || {};
        errors.address.zipCode = "Required";
    }
    if (!values.contact.type) {
        errors.contact = errors.contact || {};
        errors.contact.type = "Required";
    }
    if (!values.contact.name) {
        errors.contact = errors.contact || {};
        errors.contact.name = "Required";
    }
    if (!values.contact.phone) {
        errors.contact = errors.contact || {};
        errors.contact.phone = "Required";
    }
    if (!values.contact.email) {
        errors.contact = errors.contact || {};
        errors.contact.email = "Required";
    }
    return errors;
};

const validateEdit = values => {
    const errors = {};
    const requiredFields = [
        "name",
        "status",
        "phone",
        "email",
        "contact.name",
        "contact.email",
        "contact.phone",
        "contact.role"
    ];
    requiredFields.forEach(field => {
        let _value = _get(values, `${field}`);
        if (!_value) {
            _set(errors, `${field}`, "Required");
        }
    });

    const contactEmail = values.contact && values.contact.email;
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    } else if (
        contactEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)
    ) {
        _set(errors, `contact.email`, "Invalid email address");
    }
    return errors;
};

const validateProperties = values => {
    const errors = {};

    if (!values.bucket) {
        errors.bucket = "Required";
    }

    return errors;
};

export { validate, validateEdit, validateProperties };
export default validate;

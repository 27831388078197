import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./equipmentActionTypes";

export const setEquipments = value => ({
    type: TYPES.EQUIPMENTS,
    value
});

export const setEquipmentCount = value => ({
    type: TYPES.EQUIPMENT_COUNT,
    value
});

export function getEquipmentCount(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/equipment/count`,
                method: "get",
                params
            });

            const response = _get(_response, "data.count", 0);
            dispatch(setEquipmentCount(response))
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function getEquipments(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/equipment`,
                method: "get",
                params
            });

            const response = _get(_response, "data", []);

            dispatch(setEquipments(response));
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function createEquipment(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/equipment`,
                timeout: 20000,
                method: "post",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function updateEquipment(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/equipment`,
                timeout: 20000,
                method: "put",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

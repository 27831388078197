const defaultState = {
    count: 0,
    customers: []
};

export default function customer(state = defaultState, action) {
    switch (action.type) {
        case "CUSTOMERS":
            return {
                ...state,
                customers: action.value
            };
        case "CUSTOMERS_COUNT":
            return {
                ...state,
                count: action.value
            };
        default:
            return state;
    }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
}));

export default function CheckboxList(props) {
    const equipments = props.equipments || [];
    const classes = useStyles();
    const [checked, setChecked] = React.useState([0]);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    return (
        <List className={classes.root}>
            {equipments.length === 0 && (
                <ListItem>
                    <ListItemText id={"noitems"} primary={`No equipments`} />
                </ListItem>
            )}

            {equipments.map(item => {
                const customerName = item.customer ? item.customer.name : "";
                const labelId = `equipment-list-label-${item.id}`;

                return (
                    <ListItem
                        key={item.id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(item)}
                    >
                        <ListItemText
                            id={labelId}
                            secondary={`${customerName}`}
                            primary={`${item.name} (${item.type})`}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

// export default withStyles(styles)(EquipmentList);

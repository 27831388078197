export const ALL_TODO_TYPES = [
    {
        name: "New",
        id: "NEW"
    },
    {
        name: "Done",
        id: "DONE"
    },
    {
        name: "InProgress",
        id: "IN_PROGRESS"
    }
];

export const ALL_CUSTOMER_TYPES = [
    {
        name: "Carrier",
        id: "CARRIER"
    }
];

export const ALL_STATUS = [
    {
        name: "Active",
        id: "ACTIVE"
    },
    {
        name: "Out Of Service",
        id: "OUT_OF_SERVICE"
    },
    {
        name: "Under Repair",
        id: "UNDER_REPAIR"
    },
    {
        name: "Parts Ordered",
        id: "PARTS_ORDERED"
    },
    {
        name: "Complete",
        id: "COMPLETE"
    },
    {
        name: "Delete",
        id: "DELETE"
    }
];

export const EQP_TYPES = [
    {
        name: "Trailer",
        id: "TRAILER"
    },
    {
        name: "Tractor",
        id: "TRACTOR"
    }
];

export const USER_ROLES = [
    {
        id: "ADMIN",
        name: "Admin user"
    },
    {
        id: "PARTNER",
        name: "Partner can manage multiple shops"
    },
    {
        id: "MANAGER",
        name: "Manager can manage his own shops"
    },
    {
        id: "ACCOUNTANT",
        name: "Accountant of fleet"
    },
    {
        id: "MECHANIC",
        name: "Mechanic of fleet"
    },
    {
        id: "VIEW_ONLY",
        name: "View only user"
    },
    {
        id: "CUSTOMER",
        name: "Shop customer only user"
    },
    {
        id: "GUEST",
        name: "Guest only user"
    },
    {
        id: "API",
        name: "Api only user"
    }
];

export const USER_SCOPES = [
    {
        id: "ALL",
        name: "ALL",
        isAdmin: true,
        details: "All scopes"
    },
    {
        id: "SHOPS",
        name: "SHOPS",
        isAdmin: true,
        details: "All shops scopes"
    },
    {
        id: "SHOP",
        name: "SHOP",
        isAdmin: false,
        details: "All shop scopes"
    },

    {
        id: "A:CUSTOMER",
        name: "A:CUSTOMER",
        isAdmin: false,
        details: "Can read, add, edit and delete customer"
    },
    {
        id: "W:CUSTOMER",
        name: "W:CUSTOMER",
        isAdmin: false,
        details: "Can read, add and edit customer"
    },
    {
        id: "R:CUSTOMER",
        name: "R:CUSTOMER",
        isAdmin: false,
        details: "Can read customer"
    },

    {
        id: "A:EQP",
        name: "A:EQP",
        isAdmin: false,
        details: "Can read, add, edit and delete equipment"
    },
    {
        id: "W:EQP",
        name: "W:EQP",
        isAdmin: false,
        details: "Can read, add and edit equipment"
    },
    {
        id: "R:EQP",
        name: "R:EQP",
        isAdmin: false,
        details: "Can read equipment"
    },

    {
        id: "A:EST",
        name: "A:EST",
        isAdmin: false,
        details: "Can read, add, edit and delete estimate"
    },
    {
        id: "W:EST",
        name: "W:EST",
        isAdmin: false,
        details: "Can read, add and edit estimate"
    },
    {
        id: "R:EST",
        name: "R:EST",
        isAdmin: false,
        details: "Can read estimate"
    },

    {
        id: "A:IVT",
        name: "A:IVT",
        isAdmin: false,
        details: "Can read, add, edit and delete inventory"
    },
    {
        id: "W:IVT",
        name: "W:IVT",
        isAdmin: false,
        details: "Can read, add and edit inventory"
    },
    {
        id: "R:IVT",
        name: "R:IVT",
        isAdmin: false,
        details: "Can read inventory"
    },

    {
        id: "A:INV",
        name: "A:INV",
        isAdmin: false,
        details: "Can read, add, edit and delete invoice"
    },
    {
        id: "W:INV",
        name: "W:INV",
        isAdmin: false,
        details: "Can read, add and edit invoice"
    },
    {
        id: "R:INV",
        name: "R:INV",
        isAdmin: false,
        details: "Can read invoice"
    },

    {
        id: "A:USR",
        name: "A:USR",
        isAdmin: false,
        details: "Can read, add, edit and delete user"
    },
    {
        id: "W:USR",
        name: "W:USR",
        isAdmin: false,
        details: "Can read, add and edit user"
    },
    {
        id: "R:USR",
        name: "R:USR",
        isAdmin: false,
        details: "Can read user"
    },

    {
        id: "A:TODO",
        name: "A:TODO",
        isAdmin: false,
        details: "Can read, add, edit and delete todo"
    },
    {
        id: "W:TODO",
        name: "W:TODO",
        isAdmin: false,
        details: "Can read, add and edit todo"
    },
    {
        id: "R:TODO",
        name: "R:TODO",
        isAdmin: false,
        details: "Can read todo"
    },

    {
        id: "A:VHE",
        name: "A:VHE",
        isAdmin: false,
        details: "Can read, add, edit and delete vehicle"
    },
    {
        id: "W:VHE",
        name: "W:VHE",
        isAdmin: false,
        details: "Can read, add and edit vehicle"
    },
    {
        id: "R:VHE",
        name: "R:VHE",
        isAdmin: false,
        details: "Can read vehicle"
    }
];

export const INVOICE_STATUS = [
    {
        name: "New",
        id: "NEW"
    },
    {
        name: "Draft",
        id: "DRAFT"
    },
    {
        name: "Final",
        id: "FINAL"
    },
    {
        name: "Paid",
        id: "PAID"
    },
    {
        name: "Refused",
        id: "REFUSED"
    },
    {
        name: "Canceled",
        id: "CANCELED"
    }
];

export const COUNTRIES = [
    {
        name: "United States",
        id: "US"
    },
    {
        name: "Canada",
        id: "CA"
    }
];

export const US_STATES = [
    {
        name: "Alabama",
        id: "AL",
        country: "US"
    },
    {
        name: "Alaska",
        id: "AK",
        country: "US"
    },
    {
        name: "American Samoa",
        id: "AS",
        country: "US"
    },
    {
        name: "Arizona",
        id: "AZ",
        country: "US"
    },
    {
        name: "Arkansas",
        id: "AR",
        country: "US"
    },
    {
        name: "California",
        id: "CA",
        country: "US"
    },
    {
        name: "Colorado",
        id: "CO",
        country: "US"
    },
    {
        name: "Connecticut",
        id: "CT",
        country: "US"
    },
    {
        name: "Delaware",
        id: "DE",
        country: "US"
    },
    {
        name: "District Of Columbia",
        id: "DC",
        country: "US"
    },
    {
        name: "Federated States Of Micronesia",
        id: "FM",
        country: "US"
    },
    {
        name: "Florida",
        id: "FL",
        country: "US"
    },
    {
        name: "Georgia",
        id: "GA",
        country: "US"
    },
    {
        name: "Guam",
        id: "GU",
        country: "US"
    },
    {
        name: "Hawaii",
        id: "HI",
        country: "US"
    },
    {
        name: "Idaho",
        id: "ID",
        country: "US"
    },
    {
        name: "Illinois",
        id: "IL",
        country: "US"
    },
    {
        name: "Indiana",
        id: "IN",
        country: "US"
    },
    {
        name: "Iowa",
        id: "IA",
        country: "US"
    },
    {
        name: "Kansas",
        id: "KS",
        country: "US"
    },
    {
        name: "Kentucky",
        id: "KY",
        country: "US"
    },
    {
        name: "Louisiana",
        id: "LA",
        country: "US"
    },
    {
        name: "Maine",
        id: "ME",
        country: "US"
    },
    {
        name: "Marshall Islands",
        id: "MH",
        country: "US"
    },
    {
        name: "Maryland",
        id: "MD",
        country: "US"
    },
    {
        name: "Massachusetts",
        id: "MA",
        country: "US"
    },
    {
        name: "Michigan",
        id: "MI",
        country: "US"
    },
    {
        name: "Minnesota",
        id: "MN",
        country: "US"
    },
    {
        name: "Mississippi",
        id: "MS",
        country: "US"
    },
    {
        name: "Missouri",
        id: "MO",
        country: "US"
    },
    {
        name: "Montana",
        id: "MT",
        country: "US"
    },
    {
        name: "Nebraska",
        id: "NE",
        country: "US"
    },
    {
        name: "Nevada",
        id: "NV",
        country: "US"
    },
    {
        name: "New Hampshire",
        id: "NH",
        country: "US"
    },
    {
        name: "New Jersey",
        id: "NJ",
        country: "US"
    },
    {
        name: "New Mexico",
        id: "NM",
        country: "US"
    },
    {
        name: "New York",
        id: "NY",
        country: "US"
    },
    {
        name: "North Carolina",
        id: "NC",
        country: "US"
    },
    {
        name: "North Dakota",
        id: "ND",
        country: "US"
    },
    {
        name: "Northern Mariana Islands",
        id: "MP",
        country: "US"
    },
    {
        name: "Ohio",
        id: "OH",
        country: "US"
    },
    {
        name: "Oklahoma",
        id: "OK",
        country: "US"
    },
    {
        name: "Oregon",
        id: "OR",
        country: "US"
    },
    {
        name: "Palau",
        id: "PW",
        country: "US"
    },
    {
        name: "Pennsylvania",
        id: "PA",
        country: "US"
    },
    {
        name: "Puerto Rico",
        id: "PR",
        country: "US"
    },
    {
        name: "Rhode Island",
        id: "RI",
        country: "US"
    },
    {
        name: "South Carolina",
        id: "SC",
        country: "US"
    },
    {
        name: "South Dakota",
        id: "SD",
        country: "US"
    },
    {
        name: "Tennessee",
        id: "TN",
        country: "US"
    },
    {
        name: "Texas",
        id: "TX",
        country: "US"
    },
    {
        name: "Utah",
        id: "UT",
        country: "US"
    },
    {
        name: "Vermont",
        id: "VT",
        country: "US"
    },
    {
        name: "Virgin Islands",
        id: "VI",
        country: "US"
    },
    {
        name: "Virginia",
        id: "VA",
        country: "US"
    },
    {
        name: "Washington",
        id: "WA",
        country: "US"
    },
    {
        name: "West Virginia",
        id: "WV",
        country: "US"
    },
    {
        name: "Wisconsin",
        id: "WI",
        country: "US"
    },
    {
        name: "Wyoming",
        id: "WY",
        country: "US"
    }
];

export const CA_STATES = [
    {
        id: "AB",
        name: "Alberta",
        country: "CA"
    },
    {
        id: "BC",
        name: "British Columbia",
        country: "CA"
    },
    {
        id: "MB",
        name: "Manitoba",
        country: "CA"
    },
    {
        id: "NB",
        name: "New Brunswick",
        country: "CA"
    },
    {
        id: "NL",
        name: "Newfoundland and Labrador",
        country: "CA",
        alt: ["Newfoundland", "Labrador"]
    },
    {
        id: "NS",
        name: "Nova Scotia",
        country: "CA"
    },
    {
        id: "NU",
        name: "Nunavut",
        country: "CA"
    },
    {
        id: "NT",
        name: "Northwest Territories",
        country: "CA"
    },
    {
        id: "ON",
        name: "Ontario",
        country: "CA"
    },
    {
        id: "PE",
        name: "Prince Edward Island",
        country: "CA"
    },
    {
        id: "QC",
        name: "Quebec",
        country: "CA"
    },
    {
        id: "SK",
        name: "Saskatchewan",
        country: "CA"
    },
    {
        id: "YT",
        name: "Yukon",
        country: "CA"
    }
];

export const MASKS = {
    minHrMask: [/[0-9]/, /[0-9]/, ":", /[0-6]/, /[0-9]/],
    minHrAMPMMask: [
        /[0-1]/,
        /\d/,
        ":",
        /[0-6]/,
        /\d/,
        " ",
        /[a,p,A,P]/,
        /[m,M]/
    ],
    phoneUSMask: [
        "(",
        /[2-9]/,
        /[0-9]/,
        /[0-9]/,
        ")",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/
    ]
};

export const SUFFIX = [
    {
        id: "Jr",
        name: "Jr"
    },
    {
        id: "II",
        name: "II"
    },
    {
        id: "Sr",
        name: "Sr"
    },
    {
        id: "III",
        name: "II"
    },
    {
        id: "IV",
        name: "IV"
    },
    {
        id: "V",
        name: "V"
    }
];

export const GPS_SOURCE = [
    {
        id: "MOBILE_DEVICE",
        name: "MOBILE DEVICE"
    },
    {
        id: "VBUS_DEVICE",
        name: "VBUS Device"
    }
];

export const THRESHOLD_SPEED = [
    {
        id: "5",
        name: "5 Mile/Hr"
    }
];

export const THEME = [
    {
        id: "theme-tangaroa",
        name: "Tangaroa"
    }
];

export const UPDATE_FREQUENCY = [
    {
        id: "60000",
        name: "1 Minute"
    },
    {
        id: "300000",
        name: "5 Minutes"
    },
    {
        id: "900000",
        name: "15 Minutes"
    }
];

export const ASSET_TYPES = [
    {
        id: "VEHICLE",
        name: "Vehicle"
    },
    {
        id: "TRAILER",
        name: "Trailer"
    }
];

// export const ALL_STATUS = {
//     REMARK: "remark",
//     ONDUTY_ND: "onDutyND",
//     DRIVING: "driving",
//     SLEEPER: "sleeper",
//     OFFDUTY: "offDuty",
//     BREAK: "break"
// };

export const STATUS_TYPES = [
    {
        id: "remark",
        name: "Remark"
    },
    {
        id: "onDutyND",
        name: "OnDutyND"
    },
    {
        id: "driving",
        name: "Driving"
    },
    {
        id: "sleeper",
        name: "Sleeper"
    },
    {
        id: "offDuty",
        name: "OffDuty"
    },
    {
        id: "break",
        name: "Break"
    }
];

export const STATUS_CHANGE_TYPES = [
    {
        id: "onDutyND",
        name: "OnDutyND"
    },
    {
        id: "driving",
        name: "Driving"
    },
    {
        id: "sleeper",
        name: "Sleeper"
    },
    {
        id: "offDuty",
        name: "OffDuty"
    }
];

export const STATUS_OTHER_TYPES = [
    {
        id: "yardMoves",
        name: "Yard Moves"
    },
    {
        id: "personal",
        name: "Personal"
    }
];

export const CONNECTTION_TYPE = [
    {
        id: "bluetooth",
        name: "Bluetooth"
    },
    {
        id: "serial",
        name: "Serial"
    },
    {
        id: "wifi",
        name: "Wi Fi"
    }
];

export const DEVICE_TYPES = [
    {
        id: "PACIFIC_TRACK",
        name: "Pacific Track"
    }
];

export const DRIVER_CARGO = [
    {
        id: "PROPERTY",
        name: "PROPERTY"
    },
    {
        id: "PASSENGER",
        name: "PASSENGER"
    }
];

export const DRIVER_CYCLE_USA = [
    {
        id: "US70hr8days",
        name: "US70hr8days"
    },
    {
        id: "US60hr7days",
        name: "US60hr7days"
    }
];

export const DRIVER_CYCLE_CA = [
    {
        id: "Can70hr7daysSouth",
        name: "Can70hr7daysSouth"
    },
    {
        id: "Can120hr14daysSouth",
        name: "Can120hr14daysSouth"
    },
    {
        id: "Can80hr7daysNorth",
        name: "Can80hr7daysNorth"
    }
];

export const DRIVER_START_TIME = [
    {
        id: "12 AM",
        name: "12 AM"
    }
    // {
    //     id: "1 AM",
    //     name: "1 AM"
    // },
    // {
    //     id: "2 AM",
    //     name: "2 AM"
    // },
    // {
    //     id: "3 AM",
    //     name: "3 AM"
    // },
    // {
    //     id: "4 AM",
    //     name: "4 AM"
    // },
    // {
    //     id: "5 AM",
    //     name: "5 AM"
    // },
    // {
    //     id: "6 AM",
    //     name: "6 AM"
    // },
    // {
    //     id: "7 AM",
    //     name: "7 AM"
    // },
    // {
    //     id: "8 AM",
    //     name: "8 AM"
    // },
    // {
    //     id: "9 AM",
    //     name: "9 AM"
    // },
    // {
    //     id: "10 AM",
    //     name: "10 AM"
    // },
    // {
    //     id: "11 AM",
    //     name: "11 AM"
    // },
    // {
    //     id: "12 PM",
    //     name: "12 PM"
    // },
    // {
    //     id: "1 PM",
    //     name: "1 PM"
    // },
    // {
    //     id: "2 PM",
    //     name: "2 PM"
    // },
    // {
    //     id: "3 PM",
    //     name: "3 PM"
    // },
    // {
    //     id: "4 PM",
    //     name: "4 PM"
    // },
    // {
    //     id: "5 PM",
    //     name: "5 PM"
    // },
    // {
    //     id: "6 PM",
    //     name: "6 PM"
    // },
    // {
    //     id: "7 PM",
    //     name: "7 PM"
    // },
    // {
    //     id: "8 PM",
    //     name: "8 PM"
    // },
    // {
    //     id: "9 PM",
    //     name: "9 PM"
    // },
    // {
    //     id: "10 PM",
    //     name: "10 PM"
    // },
    // {
    //     id: "11 PM",
    //     name: "11 PM"
    // }
];

export const TIME_ZONES = [
    {
        id: "America/New_York",
        name: "Eastern Standard Time (EST)"
    },
    {
        id: "America/Chicago",
        name: "Central Standard Time (CST)"
    },
    {
        id: "America/Denver",
        name: "Mountain Standard Time (MST)"
    },
    {
        id: "America/Los_Angeles",
        name: "Pacific Standard Time (PST)"
    }
];

export const ALL_OPTIONS = [
    {
        name: "ON",
        id: "ON"
    },
    {
        name: "OFF",
        id: "OFF"
    }
];

export const ENABLE_DISABLE = [
    {
        name: "ENABLE",
        id: true
    },
    {
        name: "DISABLE",
        id: false
    }
];

export const API_STATUS = [
    {
        name: "ACTIVE",
        id: true
    },
    {
        name: "INACTIVE",
        id: false
    }
];

export const ALL_STATUS_OPTIONS = [
    {
        name: "Active",
        id: "ACTIVE"
    },
    {
        name: "Inactive",
        id: "IN_ACTIVE"
    },
    {
        name: "Delete",
        id: "DELETE"
    }
];

export const ALL_ACCOUNT_STATUS_OPTIONS = [
    {
        name: "Active",
        id: "ACTIVE"
    },
    {
        name: "On Hold",
        id: "ON_HOLD"
    },
    {
        name: "Inactive",
        id: "IN_ACTIVE"
    },
    {
        name: "Suspended",
        id: "SUSPENDED"
    },
    {
        name: "Delete",
        id: "DELETE"
    }
];

export const ALL_STATES = [...US_STATES, ...CA_STATES];

export const EDIT_REASONS = [
    {
        id: "Rental Vehicle no ELD on-board",
        name: "Rental Vehicle no ELD on-board",
        es: "Vehículo de alquiler no ELD a bordo",
        pn: "Véhicule de location sans ELD à bord"
    },
    {
        id: "ELD Device Failure",
        name: "ELD Device Failure",
        es: "Fallo del dispositivo ELD",
        pn: "Panne de périphérique ELD"
    },
    {
        id: "Missing GPS Location",
        name: "Missing GPS Location",
        es: "Falta la ubicación del GPS",
        pn: "Localisation GPS manquante"
    },
    {
        id: "Incorrect Status Selected",
        name: "Incorrect Status Selected",
        es: "Estado incorrecto seleccionado",
        pn: "Statut incorrect sélectionné"
    },
    {
        id: "Incorrect Data Entered",
        name: "Incorrect Data Entered",
        es: "Datos incorrectos ingresados",
        pn: "Données incorrectes entrées"
    },
    {
        id: "Driver Not Logged On",
        name: "Driver Not Logged On",
        es: "Controlador no conectado",
        pn: "Pilote non connecté"
    },
    {
        id: "Engineers Road Test",
        name: "Engineers Road Test",
        es: "Ingenieros Prueba de carretera",
        pn: "Essai sur route des ingénieurs"
    },
    {
        id: "Other",
        name: "Other",
        es: "Otro",
        pn: "Autre"
    }
];

export const VIOLATIONS = {
    8: "8 Hours Driving Limit",
    11: "11 Hours Driving Limit",
    13: "13 Hours Driving Limit",
    14: "14 Hours Shift Limit",
    16: "16 Hours Shift Limit",
    30: "30 Minutes Break",
    60: "60 Hours Cycle Limit",
    70: "70 Hours Cycle Limit"
};

export const EXCEPTIONS = {
    1: "Adverse weather condition",
    2: "Once in a week",
    3: "Agriculture Exemption"
};

export const REGULATION = [
    {
        id: "ELD",
        name: "ELD"
    },
    {
        id: "LOGBOOK",
        name: "Log Book"
    }
];

export const REGULATION_ADMIN = [
    {
        id: "ELD",
        name: "ELD"
    },
    {
        id: "LOGBOOK",
        name: "Log Book"
    }
];

export const DRIVER_VIEW_OPTIONS = [
    {
        id: "SINGLE",
        name: "Single"
    },
    {
        id: "ALL",
        name: "All"
    }
];

export const NOTIFICATION_SETUP_TYPES = [
    {
        id: "OVERSPEED",
        name: "OVERSPEED"
    },
    {
        id: "HOS_VIOLATION",
        name: "HOS_VIOLATION"
    }
];
